import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';

import 'components/UploadZone.scss';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

const CancelToken = axios.CancelToken;

class UploadZone extends Component {
    constructor(props){
        super(props);
        this.state={
            hover:false,
            uploading:false,
            progress:0,
        }
        this.cancel=()=>null;
        this.handleDragEnter=this.handleDragEnter.bind(this);
        this.handleDragLeave=this.handleDragLeave.bind(this);
        this.handleUpload=this.handleUpload.bind(this);
        this.handleUploadCancel=this.handleUploadCancel.bind(this);
        this.handleDropRejected=this.handleDropRejected.bind(this);
    }
    handleDropRejected(){
        this.setState({error:'Le fichier n\'est pas au bon format ('+this.props.accept+')'});
        setTimeout(()=>this.setState({error:''}),2000);
    }
    handleUploadCancel(e){
        e.preventDefault();
        e.stopPropagation();
        this.cancel();
    }
    handleUpload(files){
        let formData = new FormData();
        formData.append("action", "upload");
        formData.append("type", this.props.type);
        if (this.props.params){
            Object.keys(this.props.params).map((k)=>
                formData.append(k, this.props.params[k])
            )
        }
        files.map((f,i)=> formData.append("file"+i, f) );
        this.props.methods.ajax(
            formData,
            {
                onUploadProgress: (e) => this.setState({progress:e.loaded/e.total}) ,
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                cancelToken: new CancelToken((c) => this.cancel = c ),
            },
            ()=>{
                this.setState({uploading:false,progress:0,hover:false})
                if (this.props.onDone) this.props.onDone();
                return null;
            },
            (err) => {
                this.setState({uploading:false,progress:0,hover:false});
                if (axios.isCancel(err)) {
                    console.log('Request canceled');
                } else {
                    console.log(err);
                }
                return null;
            }
        );
        this.setState({uploading:true});
    }
    handleDragEnter(){
        this.setState({hover:true});
    }
    handleDragLeave(){
        this.setState({hover:false});
    }
    render() {
        let zoneClass='upload-zone';
        if (this.state.hover) zoneClass+=' hover';
        let button='';
        let progress='';
        let error='';
        if (this.state.uploading) {
            button=<Button onClick={this.handleUploadCancel}>Annuler</Button>;
            progress=<LinearProgress variant="determinate" value={this.state.progress*100} />;
        }
        if (this.state.error!=='') error=<p className="error">{this.state.error}</p>
        return <Dropzone
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                accept={this.props.accept}
                onDropAccepted={this.handleUpload}
                onDropRejected={this.handleDropRejected}
            >
            {({getRootProps, getInputProps}) => (
                <Paper {...getRootProps({className:zoneClass})}>
                    <div className="upload-zone-inner">
                        <input {...getInputProps()} />
                        <p>{this.props.label}</p>
                        <p className="notice">Cliquez ici ou déposez votre fichier dans cette zone</p>
                        {error}
                        {button}
                        {progress}
                    </div>
                </Paper>
            )}
        </Dropzone>;
    }
}
export default UploadZone;
