export default {
    compareAsc:(k,a,b)=>{
        let x=a[k].toLowerCase();
        let y=b[k].toLowerCase();
        if (x < y)
            return -1;
        else if (x === y)
            return 0;
        else
            return 1;
    },
    compareDesc:(k,a,b)=>{
        let x=a[k].toLowerCase();
        let y=b[k].toLowerCase();
        if (x > y)
            return -1;
        else if (x === y)
            return 0;
        else
            return 1;
    },
    accentsTidy:function(s) {
        var map = [
            ["[àáâãäå]", "a"],
            ["æ", "ae"],
            ["ç", "c"],
            ["[èéêë]", "e"],
            ["[ìíîï]", "i"],
            ["ñ", "n"],
            ["[òóôõö]", "o"],
            ["œ", "oe"],
            ["[ùúûü]", "u"],
            ["[ýÿ]", "y"],
        ];
        for (var i=0; i<map.length; ++i) {
            s = s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
                return (match) => {
                    if (match.toUpperCase() === match) {
                        return map[j][1].toUpperCase();
                    } else {
                        return map[j][1];
                    }
                }
            })(i));
        }
        return s;
    },
    momentFr:{
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mar._avr._mai_juin_juil._août_sep._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
            sameDay : '[Aujourd’hui à] LT',
            nextDay : '[Demain à] LT',
            nextWeek : 'dddd [à] LT',
            lastDay : '[Hier à] LT',
            lastWeek : 'dddd [dernier à] LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : 'dans %s',
            past : 'il y a %s',
            s : 'quelques secondes',
            m : 'une minute',
            mm : '%d minutes',
            h : 'une heure',
            hh : '%d heures',
            d : 'un jour',
            dd : '%d jours',
            M : 'un mois',
            MM : '%d mois',
            y : 'un an',
            yy : '%d ans'
        },
        dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
        ordinal : function (number, period) {
            switch (period) {
                // TODO: Return 'e' when day of month > 1. Move this case inside
                // block for masculine words below.
                // See https://github.com/moment/moment/issues/3375
                case 'D':
                    return number + (number === 1 ? 'er' : '');

                // Words with masculine grammatical gender: mois, trimestre, jour
                default:
                case 'M':
                case 'Q':
                case 'DDD':
                case 'd':
                    return number + (number === 1 ? 'er' : 'e');

                // Words with feminine grammatical gender: semaine
                case 'w':
                case 'W':
                    return number + (number === 1 ? 're' : 'e');
            }
        },
        meridiemParse : /PD|MD/,
        isPM : function (input) {
            return input.charAt(0) === 'M';
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
        // },
        meridiem : function (hours, minutes, isLower) {
            return hours < 12 ? 'PD' : 'MD';
        },
        week : {
            dow : 1, // Monday is the first day of the week.
            doy : 4  // Used to determine first week of the year.
        }
    }
}
