import React, { Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import 'index.css';
import 'components/Cinema.scss';
import { Path } from 'path-parser';

import { DateTimePicker } from "@material-ui/pickers";

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Euro from '@material-ui/icons/Euro';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import UploadZone from 'components/UploadZone';
import Image from 'components/Image';
import Parametres from 'utils/Parametres';

const path = new Path('/seance/:id');

class Seance extends Component {
    constructor(props){
        super(props);
        this.params=path.test(props.data.url);
        let show=_.find(this.props.modele.shows,this.params);
        if (!show) props.goTo('/seances/');
        this.state={
            show:_.cloneDeep(show),
        }
        this.handleDelete=this.handleDelete.bind(this);
        this.handleRevert=this.handleRevert.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleSelectChange=this.handleSelectChange.bind(this);
        this.canExit=this.canExit.bind(this);
    }
    handleDelete(){
        if(window.confirm('Est-vous sûr de vouloir supprimer cette séance ?')) {
            this.props.goTo('/seances/');
            this.props.methods.ajax({
                action:'del_show',
                show:this.state.show,
            });
        }
    }
    static getDerivedStateFromProps(props, state){
        let params=path.test(props.data.url);
        let newShow=_.find(props.modele.shows,params);
        if (newShow && !_.isEqual(newShow,state.propsShow)) {
            state.propsShow=_.cloneDeep(newShow);
            state.show.poster=newShow.poster;
        }
        return state;
    }
    componentDidMount(){
        if (this.props.canExit) {
            this.props.canExit(this.canExit);
        }
    }
    canExit(){
        let show=this.state.show;
        let showSrv=_.find(this.props.modeleSrv.shows,this.params);
        if (_.isEqual(show,showSrv)) return true;
        else if (window.confirm("Vos modifications n'ont pas été sauvées, quitter quand même ?"))
            return true;
    }
    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;
        let show=this.state.show;
        let modeleSrv=this.props.modeleSrv;
        let showSrv=_.find(modeleSrv.shows,this.params);
        if (name==='price') {
            value=value.replace(',','.');
            value=value.replace(/[^0-9.-]+/gi,'');
            value= value==='' ? showSrv[name] : ""+(parseFloat(value));
        }
        show[name]=value;
        this.setState({show:show});
    }
    handleSelectChange(e,v){
        let show=this.state.show;
        show={ ...show, ...v};
        this.setState({show:show});
    }
    handleRevert(){
        let modeleSrv=this.props.modeleSrv;
        let showSrv=_.find(modeleSrv.shows,this.params);
        this.setState({show:_.cloneDeep(showSrv)});
    }
    handleSubmit(e){
        e.preventDefault();
        this.props.methods.ajax({
            action:'mod_show',
            show:this.state.show,
        });
    }
    render() {
        let res='';
        if (this.state.show) {
            let show=this.state.show;
            let cinemas=this.props.modele.cinemas;
            let cinema=_.find(cinemas,{cinema_id:show.cinema_id});
            let films=this.props.modele.films;
            let film=_.find(films,{content_id:show.content_id});
            let showSrv=_.find(this.props.modeleSrv.shows,this.params);
            let submitBtn=<Button variant="contained" color="primary" type="submit">Enregister</Button>;
            if (_.isEqual(show,showSrv)) submitBtn=<Button type="submit" variant="contained" disabled>Enregistrer</Button>;
            let revertBtn=<Button variant="contained" color="primary" onClick={this.handleRevert}>Annuler</Button>;
            if (_.isEqual(show,showSrv)) revertBtn=<Button variant="contained" disabled>Annuler</Button>;
            let deleteBtn=<Button variant="contained" color="primary" onClick={this.handleDelete}>Supprimer</Button>;
            let poster=''
            if (show.poster) poster=<Image shadow src={Parametres.ajaxUrl+'/img/cinema/screen/'+show.content_id+'/'+show.poster+'.jpg'}/>;
            res=<Container maxWidth="lg" className="cinema">
                <Typography variant="h1" component="h1">
                    Séance
                </Typography>
                <Typography variant="h3" component="h2">
                    {moment(show.start_time).format('[le] DD/MM/YYYY [à] HH[h]mm')}
                </Typography>
                <Typography variant="h2" component="h2">
                    {show.content_name} &nbsp;
                    <IconButton aria-label="edit" onClick={()=>this.props.goTo('/film/'+show.content_id+'/')}>
                        <EditIcon/>
                    </IconButton>
                </Typography>
                <Typography variant="h3" component="h3" className='city'>
                    {show.cinema_name} <span className="city">/ {show.city_name}</span>  &nbsp;
                    <IconButton aria-label="edit" onClick={()=>this.props.goTo('/cinema/'+show.cinema_id+'/')}>
                        <EditIcon/>
                    </IconButton>
                </Typography>
                <div className="spacer"></div>
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <Autocomplete
                        value={film}
                        onChange={this.handleSelectChange}
                        options={films}
                        getOptionLabel={(option) => option.content_name || option.content_id }
                        style={{ width: '100%' }}

                        renderInput={(params) => <TextField {...params} variant="filled" label="Film" className="text-input"/>}
                        />
                        <DateTimePicker
                            autoOk
                            className="text-input"
                            inputVariant='filled'
                            ampm={false}
                            value={show.start_time}
                            onChange={(e,a,b)=>this.handleChange({target:{name:"start_time",value:moment(e._d).format('YYYY-MM-DD HH:mm:ss')}})}
                            allowKeyboardControl={true}
                            label="Date et heure"
                            leftArrowButtonProps={{ "aria-label": "Mois précédent" }}
                            rightArrowButtonProps={{ "aria-label": "Mois suivant" }}
                        />
                        <TextField
                        value={show.price}
                        onChange={this.handleChange}
                        label="Prix"
                        variant="filled"
                        name="price"
                        className="text-input"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Euro />
                                </InputAdornment>
                                ),
                            }
                        }
                        />
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Switch color="primary" checked={show.active==="1"} onChange={(e,v)=>this.handleChange({target:{name:"active",value: v ? "1" : "0"}})} name="active" />
                            </Grid>
                            <Grid item>Active</Grid>
                        </Grid>
                        <p>
                            {submitBtn} {revertBtn} {deleteBtn}
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                        value={show.vimeo_id}
                        onChange={this.handleChange}
                        label="Vimeo ID"
                        variant="filled"
                        name="vimeo_id"
                        className="text-input"
                        />
                        <TextField
                        value={show.live_vimeo_id}
                        onChange={this.handleChange}
                        label="Live Vimeo ID"
                        variant="filled"
                        name="live_vimeo_id"
                        className="text-input"
                        />
                        <TextField
                        value={cinema.tchat_id}
                        onChange={this.handleChange}
                        disabled={show.tchat==="0"}
                        label="Tchat ID"
                        variant="filled"
                        name="tchat_id"
                        className="text-input"
                        />
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Switch color="primary" checked={show.tchat==="1"} onChange={(e,v)=>this.handleChange({target:{name:"tchat",value: v ? "1" : "0"}})} name="tchat" />
                            </Grid>
                            <Grid item>Tchat</Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Switch color="primary" checked={show.test_mode==="1"} onChange={(e,v)=>this.handleChange({target:{name:"test_mode",value: v ? "1" : "0"}})} name="tchat" />
                            </Grid>
                            <Grid item>Mode test</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UploadZone
                            label="Image pour l'écran"
                            methods={this.props.methods}
                            type="screen"
                            accept='image/jpeg'
                            params={show}
                        />
                        <div className="spacer"></div>
                        {poster}
                    </Grid>
                    </Grid>
                </form>
        </Container>
        }
        return res;
    }
}
export default Seance;
