import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import './Router.scss';
import Footer from 'components/Footer';

class Router extends Component {
    constructor(props) {
        super(props);
        this.state={
            previousComponent:null,
            currentComponent:null,
            currentRoute:{template:''},
            current:null,
            previous:null
        }
        this.beforeExit=()=>true;
        this.go=this.go.bind(this);
        this.removePrevious=this.removePrevious.bind(this);
        this.handleCanExit=this.handleCanExit.bind(this);
    }
    handleCanExit(cb){
        this.beforeExit=cb;
    }
    componentDidMount() {
        this.props.history.listen((location, action) => {
            console.log(action);
            if (action!=='REPLACE') {
                let url=location.pathname.replace(this.base,'');
                this.go(url);
            }
        });
        let url=window.location.pathname.replace(this.base,'');
        console.log(url);
        this.go(url);
    }
    removePrevious(){
        this.setState({previousComponent:null,previous:null,previousRoute:null});
    }
    go(url){
        this.props.methods.appStateSet({drawerOpen:false});
        if(this.beforeExit()){
            this.beforeExit=()=>true;
            let currentRoute, currentTemplate, currentComponent, state;
            state=this.state;
            if(url !== this.state.current) {
                currentRoute=_.find(this.props.routes,{url:url});
                if (!currentRoute){
                    if (this.props.routes.length>0)
                       this.props.history.replace(this.props.routes[0].url);
                } else {
                    console.log(currentRoute.template,this.state.currentRoute.template);
                    if(currentRoute.template !== this.state.currentRoute.template){
                        currentTemplate=_.find(this.props.templates,{id:currentRoute.template});
                        currentComponent=currentTemplate.component;
                        if (this.state.currentComponent) {
                            state.previousComponent=this.state.currentComponent;
                            state.previousRoute=this.state.currentRoute;
                            state.previous=this.state.current;
                        }
                        state.currentComponent=currentComponent;
                        state.sameComponent=false;
                    } else {
                        state.sameComponent=true;
                    }
                    state.currentRoute=currentRoute;
                    state.current=url;
                    this.setState(state);
                }
            }
            this.props.onRouteHasChanged(state.currentRoute);
        } else {
            this.props.history.replace(this.state.current);
        }
    }
    render() {
        let Cp=this.state.previousComponent;
        let previous='';
        if (Cp) previous=<Cp
            goTo={this.props.goTo}
            data={this.state.previousRoute}
            modele={this.props.modele}
            modeleSrv={this.props.modeleSrv}
            appState={this.props.appState}
            methods={this.props.methods}
            />;
        let Cc=this.state.currentComponent;
        let current='';
        if (Cc) current=<Cc
            goTo={this.props.goTo}
            data={this.state.currentRoute}
            modele={this.props.modele}
            modeleSrv={this.props.modeleSrv}
            appState={this.props.appState}
            methods={this.props.methods}
            canExit={this.handleCanExit}
            />;
        let pages=[
            {url:this.state.previous,content:previous,type:'previous'},
            {url:this.state.current,content:current,type:'current',sameComponent:this.state.sameComponent}
        ];
        return (
            <div className="router">
                {pages.map((p)=>{
                    let pClass="router-route router-"+p.type;
                    if (p.sameComponent) pClass="router-route router-samecomponent router-"+p.type;
                    if (p.url)
                        return <div className={pClass} key={p.url} onTransitionEnd={this.removePrevious}>
                            {p.content}
                            <Footer
                            modele={this.props.modele}
                            goTo={this.props.goTo}
                            />
                        </div>;
                    else
                        return '';
                })}
            </div>
        );
    }
}
export default Router;
