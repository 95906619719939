import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Cinema.scss';
import { Path } from 'path-parser';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Utils from 'utils/Utils';

const path = new Path('/film/:content_id');

class AddCinema extends Component {
    constructor(props){
        super(props);
        this.params=path.test(props.data.url);
        this.state={
            newCinemaId:'',
            newCinemaName:'',
            newCityName:'',
            newCinemaError:'',
        }
        this.handleNewCinemaNameChange=this.handleNewCinemaNameChange.bind(this);
        this.handleNewCityNameChange=this.handleNewCityNameChange.bind(this);
        this.handleCreateNewCinema=this.handleCreateNewCinema.bind(this);
    }
    handleNewCinemaNameChange(e){
        let cinemaName=e.target.value;
        let cityName=this.state.newCityName;
        let corrected=Utils.accentsTidy(cinemaName+'-'+cityName).toLowerCase().replace(/[^a-z0-9_]+/gi,'_');
        let error='';
        if (_.find(this.props.modele.cinemas,{cinema_id:corrected})) error='Il y a déjà un cinema avec ce nom.';
        this.setState({
            newCinemaId:corrected,
            newCinemaName:cinemaName,
            newCinemaError:error
        });
    }
    handleNewCityNameChange(e){
        let cityName=e.target.value;
        let cinemaName=this.state.newCinemaName;
        let corrected=Utils.accentsTidy(cinemaName+'-'+cityName).toLowerCase().replace(/[^a-z0-9_]+/gi,'_');
        let error='';
        if (_.find(this.props.modele.cinemas,{cinema_id:corrected})) error='Il y a déjà un cinema avec ce nom.';
        this.setState({
            newCinemaId:corrected,
            newCityName:cityName,
            newCinemaError:error
        });
    }
    handleCreateNewCinema(e){
        e.preventDefault();
        this.setState({openNewCinemaDialog:false});
        this.props.methods.ajax(
            {
                action:'add_cinema',
                cinema:{
                    cinema_id:this.state.newCinemaId,
                    cinema_name:this.state.newCinemaName,
                    city_name:this.state.newCityName,
                    latitude:47.0,
                    longitude:2.5,
                    distance:15,
                }
            },
            {},
            ()=>this.props.goTo('/cinema/'+this.state.newCinemaId+'/')
        );
    }
    render() {
        return <Dialog open={this.props.open} onClose={this.props.onClose}>
            <form onSubmit={this.handleCreateNewCinema}>
            <DialogTitle>Nouveau cinema</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Merci de saisir un nom et une ville pour ce nouveau cinema.
                </DialogContentText>
                <TextField
                autoFocus
                className="text-input"
                variant="filled"
                label="Nom du cinéma"
                value={this.state.newCinemaName}
                onChange={this.handleNewCinemaNameChange}
                fullWidth
                />
                <TextField
                className="text-input"
                variant="filled"
                label="Ville"
                value={this.state.newCityName}
                onChange={this.handleNewCityNameChange}
                fullWidth
                />
                <p>{this.state.newCinemaError}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                Annuler
                </Button>
                <Button type="submit" color="primary" disabled={this.state.newCinemaId==='' || this.state.newCinemaError!==''}>
                Ajouter
                </Button>
            </DialogActions>
        </form>
        </Dialog>;
    }
}
export default AddCinema;
