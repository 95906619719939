import React, { Component } from 'react';
import 'index.css';
import 'components/Footer.scss';

class Footer extends Component {
    render() {
        return <div className="footer">
            <div className="background"></div>
            <div className="content">
            </div>
        </div>;
    }
}
export default Footer;
