import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Cinemas.scss';

import Utils from 'utils/Utils';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';

import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

class Cinemas extends Component {
    constructor(props){
        super(props);
        this.state={
            page:1,
            itemsParPage:12,
            filter:{
                cinema_name:'',
                city_name:'',
            },
        }
        this.handleChange=this.handleChange.bind(this);
        this.handleDelete=this.handleDelete.bind(this);
        this.handlePageChange=this.handlePageChange.bind(this);
        this.handleClearFilter=this.handleClearFilter.bind(this);
    }
    handleDelete(cinema){
        if(window.confirm('Est-vous sûr de vouloir supprimer ce cinéma ?')) {
            this.props.methods.ajax({
                action:'del_cinema',
                cinema:cinema,
            });
        }
    }
    handlePageChange (event, value) {
        if (this.props.global) {
            this.props.methods.appStateSet({pageCinemas:value});
        } else {
            this.setState({page:value});
        }
    };
    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;
        if (this.props.global) {
            let appState=this.props.appState;
            appState.cinemasFilter[name]=value;
            this.props.methods.appStateSet(appState);
        } else {
            let filter=this.state.filter;
            filter[name]=value;
            this.setState({filter:filter});
        }
        this.setState({page:1});
    }
    handleClearFilter(key){
        if (this.props.global) {
            let appState=this.props.appState;
            appState.cinemasFilter[key]='';
            this.props.methods.appStateSet(appState);
        } else {
            let filter=this.state.filter;
            filter[key]='';
            this.setState({filter:filter});
        }
        this.setState({page:1});
    }
    render() {
        let shows=this.props.modele.shows;
        let filter=this.state.filter;
        if (this.props.global)
            filter=this.props.appState.cinemasFilter;
        let cinemas=[];
        if (this.props.cinemas) cinemas=this.props.cinemas;
        else cinemas=this.props.modele.cinemas;
        let filtered=_.filter(cinemas,(c)=>{
            let cleanCinemaCinemaName, cleanFilterCinemaName, cleanCinemaCityName, cleanFilterCityName;
            let cinemaTest=true;
            let cityTest=true;
            if (filter.cinema_name) {
                cleanCinemaCinemaName=Utils.accentsTidy(c.cinema_name).toLowerCase();
                cleanFilterCinemaName=Utils.accentsTidy(filter.cinema_name).toLowerCase();
                cinemaTest=cleanCinemaCinemaName.indexOf(cleanFilterCinemaName)>=0;
            }
            if (filter.city_name) {
                cleanCinemaCityName=Utils.accentsTidy(c.city_name).toLowerCase();
                cleanFilterCityName=Utils.accentsTidy(filter.city_name).toLowerCase();
                cityTest=cleanCinemaCityName.indexOf(cleanFilterCityName)>=0;
            }
            return cinemaTest && cityTest;
        });
        let page=this.state.page;
        if (this.props.global) page=this.props.appState.pageCinemas;
        let selection=_.filter(filtered,(s,i)=>
            i>=(page-1)*this.state.itemsParPage
            && i<(page)*this.state.itemsParPage
        )
        let nbPages=Math.ceil((filtered.length-1)/this.state.itemsParPage);
        return <div>
            <Grid container spacing={3} className="main-grid">
            <Grid item xs={12} lg={6}>
            <TextField
                label="Cinema"
                name="cinema_name"
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                  <InputAdornment position="end" className="clickable">
                      <Clear onClick={()=>this.handleClearFilter('cinema_name')}/>
                  </InputAdornment>
                  ),
                }}
                value={filter.cinema_name}
                onChange={this.handleChange}
                className="text-input"
            />
            </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
                label="Ville"
                name="city_name"
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                  <InputAdornment position="end" className="clickable">
                      <Clear onClick={()=>this.handleClearFilter('city_name')}/>
                  </InputAdornment>
                  ),
                }}
                value={filter.city_name}
                onChange={this.handleChange}
                className="text-input"
            />
            </Grid>
            </Grid>
            <Grid container spacing={3} className="main-grid">
            <Grid item xs={12} md={6}>
                <Pagination count={nbPages} page={page} onChange={this.handlePageChange} className="pagination-center"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <p className="page-infos">
                {(page-1)*this.state.itemsParPage+1} - {Math.min(filtered.length,(page)*this.state.itemsParPage)} de {filtered.length}
                </p>
            </Grid>
            </Grid>

            <Grid container spacing={3}>
            {selection.map((c)=>{
                let seances=_.filter(shows,c);
                let nSeance='';
                if (seances.length<=1) nSeance=seances.length+' séance';
                else nSeance=seances.length+' séances';
                return (<Grid item xs={6} md={4} lg={3} key={c.cinema_id}>
                    <Card className="card">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                            {c.cinema_name}
                            </Typography>
                            <Typography variant="h6" component="h3" className="city">
                            {c.city_name}
                            </Typography>
                            <Typography variant="body1" component="p">
                            {nSeance}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" variant="contained" onClick={()=>this.props.goTo('/cinema/'+c.cinema_id+'/')}>modifier</Button>
                            <Button size="small" variant="contained" disabled={seances.length>0} onClick={()=>this.handleDelete(c)}>supprimer</Button>
                        </CardActions>
                    </Card>
                </Grid>);
            })}
            </Grid>
            <div className="spacer"></div>
            <Pagination count={nbPages} page={page} onChange={this.handlePageChange} className="pagination-center"/>

        </div>;
    }
}
export default Cinemas;
