import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Cinema.scss';
import { Path } from 'path-parser';

import { Map, TileLayer, Marker, Circle } from 'react-leaflet';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import SeancesList from 'components/SeancesList';
import AddSeance from 'components/AddSeance';

const path = new Path('/cinema/:cinema_id');


class Cinema extends Component {
    constructor(props){
        super(props);
        this.params=path.test(props.data.url);
        this.state={
            zoom:8,
            cinema:_.cloneDeep(_.find(this.props.modele.cinemas,this.params)),
            openNewSeanceDialog:false,
            scrollWheelZoom:false,
        }
        this.zoomTimeout=null;
        this.handleRevert=this.handleRevert.bind(this);
        this.handleDelete=this.handleDelete.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleZoomend=this.handleZoomend.bind(this);
        this.handleMapClick=this.handleMapClick.bind(this);
        this.handleNewSeanceDialogOpen=this.handleNewSeanceDialogOpen.bind(this);
        this.handleNewSeanceDialogClose=this.handleNewSeanceDialogClose.bind(this);
        this.handleMouseEnterMap=this.handleMouseEnterMap.bind(this);
        this.handleMouseLeaveMap=this.handleMouseLeaveMap.bind(this);
    }
    handleMouseEnterMap(){
        console.log('enter');
        this.zoomTimeout=setTimeout(()=>this.setState({scrollWheelZoom:true}),500);
    }
    handleMouseLeaveMap(){
        clearTimeout(this.zoomTimeout);
        this.setState({scrollWheelZoom:false});
    }
    handleNewSeanceDialogOpen(e){
        this.setState({openNewSeanceDialog:true});
    }
    handleNewSeanceDialogClose(e){
        this.setState({openNewSeanceDialog:false});
    }
    handleDelete(){
        if(window.confirm('Est-vous sûr de vouloir supprimer cette séance ?')) {
            this.props.goTo('/cinemas/');
            this.props.methods.ajax({
                action:'del_cinema',
                cinema:this.state.cinema,
            });
        }
    }
    componentWillUnmount(){
        clearTimeout(this.zoomTimeout);
        this.handleRevert();
    }
    handleZoomend(e){
        this.setState({zoom:e._zoom});
    }
    handleMapClick(e){
        let cinema=this.state.cinema;
        cinema.latitude=""+parseFloat(parseInt(e.latlng.lat*1000000)/1000000);
        cinema.longitude=""+parseFloat(parseInt(e.latlng.lng*1000000)/1000000);
        this.setState({cinema:cinema});
    }
    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;
        let modeleSrv=this.props.modeleSrv;
        let cinemaSrv=_.find(modeleSrv.cinemas,this.params);
        if (['latitude','longitude'].indexOf(name)>=0) {
            value=value.replace(',','.');
            value=value.replace(/[^0-9.-]+/gi,'');
            value= value==='' ? cinemaSrv[name] : ""+(parseFloat(value));
        }
        if (['distance'].indexOf(name)>=0) {
            value= value==='' ? cinemaSrv[name] : ""+parseInt(value);
        }
        let cinema=this.state.cinema;
        cinema[name]=value;
        this.setState({cinema:cinema});
    }
    handleRevert(){
        let modeleSrv=this.props.modeleSrv;
        let cinemaSrv=_.find(modeleSrv.cinemas,this.params);
        this.setState({cinema:_.cloneDeep(cinemaSrv)});
    }
    handleSubmit(e){
        e.preventDefault();
        let cinema=this.state.cinema;
        cinema.latitude=parseFloat(cinema.latitude).toFixed(6);
        cinema.longitude=parseFloat(cinema.longitude).toFixed(6);
        this.props.methods.ajax({
            action:'mod_cinema',
            cinema:this.state.cinema,
        });
        this.setState({cinema:cinema});
    }
    render() {
        let res='';
        if (this.params && this.params.cinema_id) {
            let cinema=this.state.cinema;
            let cinemaSrv=_.find(this.props.modeleSrv.cinemas,this.params);
            let shows=_.filter(this.props.modele.shows,this.params);
            let submitBtn=<Button variant="contained" color="primary" type="submit">Enregister</Button>;
            if (_.isEqual(cinema,cinemaSrv)) submitBtn=<Button type="submit" variant="contained" disabled>Enregistrer</Button>;
            let revertBtn=<Button variant="contained" color="primary" onClick={this.handleRevert}>Annuler</Button>;
            if (_.isEqual(cinema,cinemaSrv)) revertBtn=<Button variant="contained" disabled>Annuler</Button>;
            let deleteBtn=<Button variant="contained" color="primary" disabled={shows.length>0} onClick={this.handleDelete}>Supprimer</Button>;
            res=<Container maxWidth="lg" className="cinema">
                <Typography variant="h1" component="h1">
                    Cinéma
                </Typography>
                <Typography variant="h2" component="h2">
                    {cinema.cinema_name}
                </Typography>
                <Typography variant="h3" component="h3" className='city'>
                    {cinema.city_name}
                </Typography>
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <TextField
                        value={cinema.cinema_name}
                        onChange={this.handleChange}
                        label="Nom du cinéma"
                        variant="filled"
                        name="cinema_name"
                        className="text-input"
                        />
                        <TextField
                        value={cinema.city_name}
                        onChange={this.handleChange}
                        label="Ville"
                        variant="filled"
                        name="city_name"
                        className="text-input"
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                        value={parseFloat(cinema.latitude)}
                        onChange={this.handleChange}
                        label="Latitude"
                        variant="filled"
                        name="latitude"
                        className="text-input"
                        />
                        <TextField
                        value={parseFloat(cinema.longitude)}
                        onChange={this.handleChange}
                        label="Longitude"
                        variant="filled"
                        name="longitude"
                        className="text-input"
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                        value={cinema.distance}
                        onChange={this.handleChange}
                        label="Distance"
                        variant="filled"
                        type="number"
                        name="distance"
                        className="text-input"
                        />
                    </Grid>
                    </Grid>
                    <p>
                        {submitBtn} {revertBtn} {deleteBtn}
                    </p>
                </form>
                <div
                    onMouseEnter={this.handleMouseEnterMap}
                    onMouseLeave={this.handleMouseLeaveMap}
                >
                <Map center={[cinema.latitude, cinema.longitude]} scrollWheelZoom={this.state.scrollWheelZoom} zoom={this.state.zoom} onZoomend={this.handleZoomend} onClick={this.handleMapClick}>
                    <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Circle center={[cinema.latitude, cinema.longitude]} color="#3f51b5" radius={cinema.distance*1000}>
                    </Circle>
                    <Marker position={[cinema.latitude, cinema.longitude]}>
                    </Marker>
                </Map>
                </div>
                <div className="spacer"></div>
                <Typography variant="h2" component="h2">
                    Séances &nbsp;
                    <Fab color="primary" size="small" aria-label="add" onClick={this.handleNewSeanceDialogOpen}>
                        <AddIcon />
                    </Fab>
                </Typography>
                <AddSeance cinemaId={this.state.cinema.cinema_id} open={this.state.openNewSeanceDialog} { ... this.props} onClose={this.handleNewSeanceDialogClose}/>
                <SeancesList shows={shows} { ...this.props}/>
        </Container>
        }
        return res;
    }
}
export default Cinema;
