import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Cinema.scss';
import { Path } from 'path-parser';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import UploadZone from 'components/UploadZone';
import Image from 'components/Image';
import AddSeance from 'components/AddSeance';
import SeancesList from 'components/SeancesList';
import CinemasList from 'components/CinemasList';
import Parametres from 'utils/Parametres';

const path = new Path('/film/:content_id');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

class Film extends Component {
    constructor(props){
        super(props);
        this.params=path.test(props.data.url);
        this.state={
            openNewSeanceDialog:false,
            openNewCinemaDialog:false,
            tabSelected:0,
            film:_.cloneDeep(_.find(this.props.modele.films,this.params)),
        }
        this.handleRevert=this.handleRevert.bind(this);
        this.handleDelete=this.handleDelete.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleSelectChange=this.handleSelectChange.bind(this);
        this.handleTabChange=this.handleTabChange.bind(this);
        this.handleUploaded=this.handleUploaded.bind(this);
        this.canExit=this.canExit.bind(this);
        this.handleNewSeanceDialogOpen=this.handleNewSeanceDialogOpen.bind(this);
        this.handleNewSeanceDialogClose=this.handleNewSeanceDialogClose.bind(this);
    }
    handleNewSeanceDialogOpen(){
        this.setState({openNewSeanceDialog:true});
    }
    handleNewSeanceDialogClose(e){
        this.setState({openNewSeanceDialog:false});
    }
    handleDelete(){
        if(window.confirm('Est-vous sûr de vouloir supprimer ce film ?')) {
            this.props.goTo('/films/');
            this.props.methods.ajax({
                action:'del_film',
                film:this.state.film,
            });
        }
    }
    handleUploaded(){
        let hash=this.props.appState.posterHash+1;
        this.props.methods.appStateSet({posterHash:hash});
    }
    componentDidMount(){
        if (this.props.canExit) {
            this.props.canExit(this.canExit);
        }
    }
    canExit(){
        let film=this.state.film;
        let filmSrv=_.find(this.props.modeleSrv.films,this.params);
        if (_.isEqual(film,filmSrv)) return true;
        else if (window.confirm("Vos modifications n'ont pas été sauvées, quitter quand même ?"))
            return true;
    }
    handleTabChange(e,id){
        console.log(id);
        this.setState({tabSelected:id});
    }
    handleChange(e){
        let name=e.target.name;
        let value=e.target.value;
        let film=this.state.film;
        film[name]=value;
        this.setState({film:film});
    }
    handleSelectChange(e,v){
        let film=this.state.film;
        film={ ...film, ...v};
        this.setState({film:film});
    }
    handleRevert(){
        let modeleSrv=this.props.modeleSrv;
        let filmSrv=_.find(modeleSrv.films,this.params);
        this.setState({film:_.cloneDeep(filmSrv)});
    }
    handleSubmit(e){
        e.preventDefault();
        this.props.methods.ajax({
            action:'mod_film',
            film:this.state.film,
        });
    }
    render() {
        let res='';
        if (this.params && this.params.content_id) {
            let film=this.state.film;
            let filmSrv=_.find(this.props.modeleSrv.films,this.params);
            let submitBtn=<Button variant="contained" color="primary" type="submit">Enregister</Button>;
            if (_.isEqual(film,filmSrv)) submitBtn=<Button type="submit" variant="contained" disabled>Enregistrer</Button>;
            let revertBtn=<Button variant="contained" color="primary" onClick={this.handleRevert}>Annuler</Button>;
            if (_.isEqual(film,filmSrv)) revertBtn=<Button variant="contained" disabled>Annuler</Button>;
            let poster='';
            let posterFile='img/cinema/poster/'+film.content_id+'.jpg';
            let posterMinFile='img/cinema/poster/min/'+film.content_id+'.jpg?'+this.props.appState.posterHash;
            let posterSrc=Parametres.ajaxUrl+posterMinFile;
            if (this.props.modele.posters.indexOf(posterFile)!==-1) poster=<div className="film-poster shadow">
                <div className="film-poster-inner">
                    <Image shadow src={posterSrc}/>
                </div>
            </div>;
            else poster=<div className="film-poster shadow">
                <div className="film-poster-inner">
                </div>
            </div>;
            let defaultScreen='';
            let defaultScreenFile='img/cinema/screen/'+film.content_id+'/default.jpg';
            let defaultScreenSrc=Parametres.ajaxUrl+defaultScreenFile;
            if (this.props.modele.default_screens.indexOf(defaultScreenFile)!==-1) defaultScreen=<Image shadow src={defaultScreenSrc}/>;
            let shows=_.filter(this.props.modele.shows,this.params);
            let cinemasIds=[];
            shows.map((s)=>{
                if(s.content_id===film.content_id && cinemasIds.indexOf(s.cinema_id)===-1) cinemasIds.push(s.cinema_id);
                return null;
            })
            let cinemas=_.filter(this.props.modele.cinemas,(c)=>cinemasIds.indexOf(c.cinema_id)>=0);
            let deleteBtn=<Button variant="contained" color="primary" disabled={shows.length>0} onClick={this.handleDelete}>Supprimer</Button>;
            res=<Container maxWidth="lg" className="cinema">
                <Typography variant="h1" component="h1">
                    Film
                </Typography>
                <Typography variant="h3" component="h2">
                    {film.content_name}
                </Typography>
                <div className="spacer"></div>
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                        value={film.content_name}
                        onChange={this.handleChange}
                        label="Titre"
                        variant="filled"
                        name="content_name"
                        className="text-input"
                        />
                        <TextField
                        value={film.teaser_id || ''}
                        onChange={this.handleChange}
                        label="Bande-annonce Vimeo ID"
                        variant="filled"
                        name="teaser_id"
                        className="text-input"
                        />
                        <UploadZone
                            label="Affiche"
                            methods={this.props.methods}
                            type="poster"
                            accept='image/jpeg'
                            params={film}
                            onDone={this.handleUploaded}
                        />
                        <p>
                            {submitBtn} {revertBtn} {deleteBtn}
                        </p>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        {poster}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <UploadZone
                            label="Écran d'accueil"
                            methods={this.props.methods}
                            type="default_screen"
                            accept='image/jpeg'
                            params={film}
                            onDone={this.handleUploaded}
                        />
                        <div className="spacer"></div>
                        {defaultScreen}
                    </Grid>
                    </Grid>
                </form>
                <div className="spacer"></div>
                <AppBar position="static" color="default">
                    <Tabs value={this.state.tabSelected} onChange={this.handleTabChange} indicatorColor="primary">
                        <Tab label="Séances" id="tab-0"/>
                        <Tab label="Cinémas" id="tab-1"/>
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.tabSelected} index={0}>
                    <div className="spacer"></div>
                    <Fab color="primary" size="small" aria-label="add" onClick={this.handleNewSeanceDialogOpen}>
                        <AddIcon />
                    </Fab>
                    <AddSeance contentId={this.state.film.content_id} { ... this.props} open={this.state.openNewSeanceDialog} onClose={this.handleNewSeanceDialogClose}/>
                    <div className="spacer"></div>
                    <SeancesList shows={shows} { ...this.props}/>
                </TabPanel>
                <TabPanel value={this.state.tabSelected} index={1}>
                    <div className="spacer"></div>
                    <CinemasList cinemas={cinemas} { ...this.props}/>
                </TabPanel>

        </Container>
        }
        return res;
    }
}
export default Film;
