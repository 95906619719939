import React, { Component } from 'react';
import 'index.css';
import 'components/Cinemas.scss';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import CinemasList from 'components/CinemasList';
import AddCinema from 'components/AddCinema';

class Cinemas extends Component {
    constructor(props){
        super(props);
        this.state={
            openNewCinemaDialog:false,
        }
        this.handleNewCinemaDialogOpen=this.handleNewCinemaDialogOpen.bind(this);
        this.handleNewCinemaDialogClose=this.handleNewCinemaDialogClose.bind(this);
    }
    handleNewCinemaDialogOpen(){
        this.setState({openNewCinemaDialog:true});
    }
    handleNewCinemaDialogClose(e){
        this.setState({openNewCinemaDialog:false});
    }
    render() {
        let cinemas=this.props.modele.cinemas;
        return <Container maxWidth="lg" className="accueil">
            <Typography variant="h1" component="h1">
                Cinémas &nbsp;
                <Fab color="primary" aria-label="add" onClick={this.handleNewCinemaDialogOpen}>
                    <AddIcon />
                </Fab>
            </Typography>
            <AddCinema { ... this.props} open={this.state.openNewCinemaDialog} onClose={this.handleNewCinemaDialogClose}/>
            <CinemasList global cinemas={cinemas} {...this.props}/>
        </Container>;
    }
}
export default Cinemas;
